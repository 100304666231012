<template>
  <div class="content">
    <el-card>
      <div class="context">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="报告类型:" prop="">
                <el-radio-group v-model="queryInfo.reportType" @change="search">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button label="单份报告">单份报告</el-radio-button>
                  <el-radio-button label="报告合集">报告合集</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="发布日期:" prop="">
                <el-date-picker
                  @change="search"
                  value-format="yyyy-MM-dd"
                  v-model="queryInfo.releaseTime"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <!-- 新增--搜索 -->
        <div class="add_search">
          <div class="left">
            <el-button
              v-if="$hasPerms('report:updown')"
              style="margin-right: 5px"
              @click="updateOnShelf"
              >批量发布</el-button
            >
            <el-button
              v-if="$hasPerms('report:updown')"
              style="margin-right: 5px"
              @click="updateOffShelf"
              >批量下架</el-button
            >
            <!--  v-if="$hasPerms('expert:add')"  -->
            <!-- <el-button type="primary" @click="toPage()">新增报告</el-button> -->
            <div class="ckeckbox_text">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
          </div>
          <div class="right">
            <el-input v-model="queryInfo.reportName" placeholder="报告名称" clearable></el-input>
            <div class="add" @click="search">搜索</div>
          </div>
        </div>

        <!-- 表格 -->
      </div>
    </el-card>
    <div
      class="tab_txt"
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div style="padding-right: 50px">
          <span>当前条件下共查出{{ total }}份报告</span>
        </div>
        <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按查看次数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1"
              :style="{ 'border-bottom-color': orderinfo.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown2"
              :style="{ 'border-top-color': orderinfo.color2 }"
            ></span>
          </div>
        </div> -->
        <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按报告原价
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp3"
              :style="{ 'border-bottom-color': orderinfo.color3 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown4"
              :style="{ 'border-top-color': orderinfo.color4 }"
            ></span>
          </div>
        </div> -->
        <div style="display: flex;cursor: pointer; align-items: center; margin-right: 30px">
          <span @click="clickMain">按发布日期</span>
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp5"
              :style="{ 'border-bottom-color': orderinfo.color5 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown6"
              :style="{ 'border-top-color': orderinfo.color6 }"
            ></span>
          </div>
        </div>
        <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按查看人数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp7"
              :style="{ 'border-bottom-color': orderinfo.color7 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown8"
              :style="{ 'border-top-color': orderinfo.color8 }"
            ></span>
          </div>
        </div> -->
        <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按分享人数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp9"
              :style="{ 'border-bottom-color': orderinfo.color9 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown10"
              :style="{ 'border-top-color': orderinfo.color10 }"
            ></span>
          </div>
        </div> -->
        <!-- <div style="display: flex; align-items: center; margin-right: 30px">
          按下载人数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp11"
              :style="{ 'border-bottom-color': orderinfo.color11 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown12"
              :style="{ 'border-top-color': orderinfo.color12 }"
            ></span>
          </div>
        </div> -->
      </div>
      <div class="storeCenter_item_h">
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img v-if="item.coverImage" :src="item.coverImage" alt="" />
              <img v-else src="../../../assets/image/Group 3237.png" alt="" />
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <img
                  width="16"
                  height="16"
                  v-if="item.reportType == '报告合集'"
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E5%8E%8B%E7%BC%A9%E5%8C%85%201.png"
                  alt=""
                />
                <img
                  width="16"
                  height="16"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/PDF%20%281%29%201.png"
                  alt=""
                />
                <h5 @click="download(item)" class="h5_name">
                  {{ item.reportName }}
                </h5>
              </div>

              <!-- <div>{{ item.sponsor }}</div> -->
              <div
                v-if="item.releaseMechanism"
                style="padding: 10px 0px 0px 0px"
                class="releaseMechanism"
              >
                出版机构：{{ item.releaseMechanism }}
              </div>
              <div v-if="item.releaseTime" class="releaseMechanism">
                发布日期：{{ item.releaseTime }}
              </div>
              <div class="h5_names">
                {{ item.reportName }}
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_center">
            <div class="count_box" @click="downloadCount(item)">
              <div>{{ item.uploadUserNumber }}</div>
              <div>下载</div>
            </div>
            <div class="count_box" @click="listUserCheckPage(item)">
              <div>{{ item.viewUserNumber||0 }}</div>
              <div>查看</div>
            </div>
            <div class="count_box" @click="listUserSharePage(item)">
              <div>{{ item.shareUserNumber }}</div>
              <div>分享</div>
            </div>
          </div>

          <div class="storeCenter_item_top_right">
            <div class="operation_area">
              <div v-if="$hasPerms('report:updown')">
                <span
                  v-if="item.isShelf == 1 || item.isShelf == null"
                  class="release"
                  @click="OnShelf(item)"
                  >发布</span
                >
                <span v-if="item.isShelf == 0" class="shelf" @click="OffShelf(item)">下架</span>
              </div>
              <el-button type="text" @click="edit(item)" style="font-size: 16px;">编辑</el-button>
              <div v-if="$hasPerms('report:freeze')">
                <span v-if="item.isDelete == 0" class="support" @click="delcompany(item)"
                  >冻结</span
                >
                <span v-if="item.isDelete == 1" class="support" @click="cancelcompany(item)"
                  >激活</span
                >
              </div>
            </div>
            <div class="details">操作人：{{ item.userName }}</div>
            <div class="details">最近操作时间：{{ item.updateTime }}</div>
          </div>
        </div>
      </div>
      </div>
     
      <div class="new_page" v-if="listData.length">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
const defaultQueryInfo = Object.freeze({
  partnerChannelId: '', //合作伙伴编号
  schemaName: '', //智参中心schema值
  releaseTime: null, //发布时间
  reportName: '', //报告名称
  reportType: '', //报告类型
  orders: '1', //排序 默认传1 2->查看次数升序;3->查看次数降序; 4->报告原价升序;5->报告原价降序;6->发布日期升序;7->发布日期降序8->查看人数升序;9->查看人数降序;10->分享人数升序;11->分享人数降序;12->下载人数升序;13->下载人数降序
  pageNum: 1,
  pageSize: 10
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6',
  color9: '',
  color10: '#C6C6C6',
  color11: '',
  color12: '#C6C6C6'
})
import {
  listPage,
  reportFreeze,
  reportThawing,
  updateOffShelf,
  updateOnShelf
} from '../../../api/report'
export default {
  data() {
    //这里存放数据
    return {
      // 遮罩层
      loading: true,
      total: 0, //咨询条
      checkAll: false,
      isIndeterminate: false,
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),
      InformationTypes: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '应用场景',
          id: 1
        },
        {
          value: '数智技术',
          id: 2
        },

        {
          value: '行业动态',
          id: 3
        },
        {
          value: '数智案例',
          id: 4
        },

        {
          value: '数智产品',
          id: 5
        },
        {
          value: '数智服务商',
          id: 6
        },
        {
          value: '政策解读',
          id: 7
        },
        {
          value: '组织人才',
          id: 8
        }
      ],

      // 只看服务商上传的咨询 的多选框
      checked: false,
      // 表格数据
      listData: [],
      // 资讯类名控制
      typesClass: 999,
      // 接口得到的资讯条数数据
      informationNUm: {},
      //  表格需要的数据
      queryInfo: { ...defaultQueryInfo },
      //排序字段
      orderinfo: { ...defaultOrder },
      // 移除内容池
      poolShow: false,
      //
      imageShow: -1,
      enterpriseIntroShow: -1,
      // 移除内容池所需要的id
      PoolId: null,
      // 移除成功的字段
      removeSucceed: 0,
      aaa: 0,
      ids: [],
      idsList: [],
      idof: [] //判断是否勾选
    }
  },
  created() {
    this.search()
  },
  methods: {
    toPage() {
      this.$router.push({
        path: '/service/eventDetails'
      })
    },
    edit(row) {
      this.$router.push({
        path: '/service/eventDetails',
        query: {
          reportId: row.id
        }
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    // 资讯类型的点击方法
    clickType(item) {
      this.typesClass = item.id
      if (item.id == 999) {
        this.query.informationType = null
        this.search()
      } else {
        this.query.informationType = item.id
        this.search()
      }

      // console.log(item.value)
    },
    // 多选框选中数据

    latechange(bol, row) {
      const index = this.idof.indexOf(row.foreignKey)
      if (index == -1) {
        this.ids.push({
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        })
        this.idof.push(row.foreignKey)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idof.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log(this.ids)
    },
    //排序按查看次数
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //排序按查看人数
    clickUp3() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '4'
      this.search()
      this.orderinfo.color3 = '#0099ff'
      this.orderinfo.color4 = ''
    },
    clickDown4() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '5'
      this.search()
      this.orderinfo.color3 = ''
      this.orderinfo.color4 = '#0099ff'
    },
    clickMain(){
      if(this.orderinfo.color5){
        this.clickDown6()
      }else{
        this.clickUp5()
      }
    },
    //排序按分享人数
    clickUp5() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '6'
      this.search()

      this.orderinfo.color5 = '#0099ff'
      this.orderinfo.color6 = ''
    },
    clickDown6() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '7'
      this.search()

      this.orderinfo.color5 = ''
      this.orderinfo.color6 = '#0099ff'
    },
    //排序按对接人数
    clickUp7() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '8'
      this.search()

      this.orderinfo.color7 = '#0099ff'
      this.orderinfo.color8 = ''
    },
    clickDown8() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '9'
      this.search()

      this.orderinfo.color7 = ''
      this.orderinfo.color8 = '#0099ff'
    },
    //排序按对接人数
    clickUp9() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '10'
      this.search()

      this.orderinfo.color9 = '#0099ff'
      this.orderinfo.color10 = ''
    },
    clickDown10() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '11'
      this.search()

      this.orderinfo.color9 = ''
      this.orderinfo.color10 = '#0099ff'
    },
    //排序按对接人数
    clickUp11() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '12'
      this.search()

      this.orderinfo.color11 = '#0099ff'
      this.orderinfo.color12 = ''
    },
    clickDown12() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '13'
      this.search()

      this.orderinfo.color11 = ''
      this.orderinfo.color12 = '#0099ff'
    },

    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的报告中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量下架报告？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then((res) => {
            if (res.code == 200) {
              this.search()
              this.ids = []
              this.isIndeterminate = false
              this.checkAll = false
              this.idof=[]
              if (res.data) {
                this.$message.warning(res.data)
              } else {
                this.$message.success('下架成功')
              }
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的报告中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量上架报告？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then((res) => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.idof=[]
            if (res.data) {
              this.$message.warning(res.data)
            } else {
              this.$message.success('上架成功')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //冻结资讯
    delcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认冻结？')
        .then(function () {
          return reportFreeze(params)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活资讯
    cancelcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认激活？')
        .then(function () {
          return reportThawing(params)
        })
        .then((res) => {
          if (res.code == 200) {
            this.search()
            this.$message.success('激活成功')
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch(() => {})
    },
    //单个下架
    OffShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该报告已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认下架报告？')
        .then(function () {
          return updateOffShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data) {
            this.$message.warning(res.data)
          } else {
            this.$message.success('下架成功')
          }
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该报告已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认上架报告？')
        .then(function () {
          return updateOnShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data) {
            this.$message.warning(res.data)
          } else {
            this.$message.success('上架成功')
          }
        })
        .catch(() => {})
    },

    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push({
            foreignKey: item.foreignKey,
            schemaName: this.parteninfo.schemaName
          })
          this.idof.push(item.foreignKey)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.idof = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },

    // 表格数据
    async search() {
      this.isIndeterminate = false
      this.checkAll = false
      this.loading = true
      this.queryInfo.partnerChannelId = this.parteninfo.id
      this.queryInfo.schemaName = this.parteninfo.schemaName

      const res = await listPage(this.queryInfo)
      if (res.code == 200) {
        this.listData = res.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.total = res.data.total
        this.removeSucceed = 1
        this.loading = false
      }
    },

    //  查看人数的跳转
    listUserCheckPage(item) {
      this.$router.push({
        name: 'reportcheck',
        query: { id: item.id }
      })
    },
    //下载人数跳转
    downloadCount(item) {
      this.$router.push({
        name: 'downloadCount',
        query: { id: item.id }
      })
    },

    //  分享人数的跳转
    listUserSharePage(item) {
      this.$router.push({
        name: 'reportshare',
        query: { id: item.id }
      })
    },

    //  收藏人数的跳转
    clickCollectNum(row) {
      // this.$router.push({
      //   path: '/content/collect',
      //   query: {
      //     row: JSON.stringify(row),
      //     activeName: '3'
      //   }
      // })
    },
    // 想联系人数的跳转
    clickLinkNumNum(row) {
      // this.$router.push({
      //   path: '/content/relation',
      //   query: {
      //     row: JSON.stringify(row),
      //     activeName: '4'
      //   }
      // })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.content {
  height: calc(100vh - 120px);
  overflow: hidden;
  .new_page {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin: 20px 0;
  }
  .head {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .head_item {
      width: 400px;
      height: 165px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      .head_left {
        width: 50%;
        height: 100%;
        color: #333;
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .head_title {
          font-size: 32px;
          color: #333;
        }
        .head_sum {
          font-size: 26px;
          color: #333;
        }
      }

      .head_right {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        padding-right: 42px;
        .head_img {
          width: 89px;
          height: 89px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .head_sum {
          // display: flex;

          .head_h2Title {
            font-size: 14px;
            color: #ff6c66;
          }
          .head_newAdd {
            margin: 0 6px;
            font-size: 26px;
            color: #ff6c66;
          }
        }
      }
      .head_right i {
        font-size: 21px;
        font-weight: 700;
        color: #ff6c66;
      }
    }
  }

  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }

  .context {
    width: 100%;
    overflow: auto;
    .types {
      height: 80px;
      border-bottom: 1px double #f3f3f3;
      display: flex;
      align-items: center;

      .title {
        margin-right: 5px;
        color: #7c7f8e;
        font-size: 14px;
      }

      /deep/.el-button {
        border: none;
        font-size: 14px;
        color: #333333;
      }
      /deep/.el-button:hover {
        border-radius: 4px;
        background-color: rgba(78, 147, 251, 0.2);
        color: #4e93fb;
        font-size: 14px;
        cursor: pointer;
      }

      .click_item {
        border-radius: 4px;
        background-color: #4e93fb !important;
        color: #fff !important;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .add_search {
      height: 64px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      .left {
        display: flex;
        align-items: flex-end;
        .add {
          width: 85px;
          height: 40px;
          border-radius: 4px;
          cursor: pointer;
          background-color: #448aff;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
        }
        .ckeckbox_text {
          margin-left: 20px;
          /deep/.el-checkbox__inner {
            width: 18px;
            height: 18px;
          }
          /deep/.el-checkbox__label {
            color: #333;
          }
          /deep/.el-checkbox__inner::after {
            height: 10px;
            left: 6px;
          }
        }
      }

      .right {
        display: flex;
        align-items: flex-end;
        .add {
          transform: translateX(-2px);
          width: 74px;
          height: 40px;
          cursor: pointer;
          background-color: #448aff;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 33px;
        }
        /deep/.el-input {
          width: 429px;
        }
      }
    }
    .table {
      width: 100%;
      .operate {
        width: 240px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .redact {
          color: #4e93fb;
          font-size: 14px;
          cursor: pointer;
        }
        .del {
          color: #fd5469;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .matching {
        width: 80px;
        height: 33px;
        background-color: #e4e4e4;
        border-radius: 2px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .label {
        width: 180px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;

        .items {
          display: inline-block;
          margin-right: 10px;
          color: #4e93fb;
        }
      }
      .suspend {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        transform: translateX(50%);
        width: 204px;
        // max-width: 204px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 1000;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        box-shadow: 0px 5px 5px rgba(143, 138, 138, 0.25);
        display: flex;
        flex-wrap: wrap;
        .items {
          // display: flex;
          margin-right: 10px;
          display: inline-block;
          color: #4e93fb;
        }
      }
      // /deep/.el-table__body-wrapper {
      //   overflow: visible;
      // }
      // /deep/.el-table {
      //   overflow: visible;
      // }

      .Nums {
        font-size: 14px;
        color: #4e93fb;
        text-decoration: underline;
        cursor: pointer;
      }
      /deep/.cell {
        overflow: visible;
      }
      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table__header-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }

      .img_video {
        width: 69px;
        height: 48px;
        // cursor: pointer;
        position: relative;
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
        }
        .magnify {
          position: absolute;
          top: 40px;
          right: -134px;
          width: 132px;
          height: 92px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .information_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .currency {
        width: 64px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background: #448aff;
        border-radius: 2px;
        color: #fff;
        margin-top: 10px;
      }

      .pagination {
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
}
.add_search {
  height: 64px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  .left {
    display: flex;
    align-items: flex-end;
    .add {
      width: 85px;
      height: 40px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #448aff;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 13px;
    }
    .ckeckbox_text {
      /deep/.el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
      /deep/.el-checkbox__label {
        color: #333;
      }
      /deep/.el-checkbox__inner::after {
        height: 10px;
        left: 6px;
      }
    }
  }

  .right {
    display: flex;
    align-items: flex-end;
    .add {
      transform: translateX(-2px);
      width: 74px;
      height: 40px;
      cursor: pointer;
      background-color: #448aff;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 33px;
    }
    /deep/.el-input {
      width: 429px;
    }
  }
}
.tab_txt {
  background: #f6f7fb;

  margin-top: 20px;
  padding: 0px;
  padding-bottom: 0px;
}

.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.storeCenter_item_h{
  height: calc(100vh - 470px);

  overflow: auto;
}
.storeCenter_item_h::-webkit-scrollbar {
      width: 2px;
      height: 4px;
      background: rgba(29, 29, 38, 0.7);
    }
    /*定义滚动条轨道*/
    .storeCenter_item_h::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
      border-radius: 10px;
      background: #fff;
    }
    /*定义滑块*/
    .storeCenter_item_h::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0px rgba(118, 118, 118, 0.5);
      background-color: rgba(118, 118, 118, 0.5);
    }
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.storeCenter_item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  .item_checkbox {
    line-height: 36px;
    margin: 0px 10px;
  }
  .Relevancy {
    position: absolute;
    width: 83px;
    height: 25px;
    top: 15px;
    left: 9px;
    background: #ff7d18;
    border-radius: 10px 15px 15px 0px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
  }
  .Relevancyblue {
    position: absolute;
    width: 83px;
    height: 25px;
    top: 15px;
    left: 9px;
    background: #146aff;
    border-radius: 10px 15px 15px 0px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    color: #ffffff;
  }
  .storeCenter_item_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 14px;
    position: relative;
    background: #ffffff;
    padding: 16px 20px 10px 10px;
    .storeCenter_item_top_left {
      display: flex;
      flex-direction: row;
      height: 107.73px;
      width: 450px;
      .banner_box {
        position: relative;
        width: 185.55px;
        height: 117.73px;
        .generalVersion {
          background: #448aff;
          border-radius: 2px;
          padding: 5px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          /* identical to box height */
          margin: 0 10px;
          color: #ffffff;
          position: absolute;
          left: 0px;
          top: 5px;
        }
        img {
          width: 185.55px;
          height: 117.73px;
          border-radius: 4px;
          margin-right: 14px;
        }
        .activity_state {
          position: absolute;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 34px;
          text-align: center;
          color: #ffffff;
          top: -10px;
          left: -9px;
          width: 78px;
          height: 34px;
          border-radius: 5px 15px 15px 0;
        }
        .not_started {
          background: #4e93fb;
        }
        .have_in_hand {
          background: #ff7d18;
        }
        .closed {
          background: #999999;
        }
      }

      .storeCenter_item_top_left_flex {
        margin-left: 10px;
        .h5_name {
          display: inline-block;
          margin: 0;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          width: 200px;
          -webkit-box-orient: vertical;
          margin-left: 5px;
          cursor: pointer;
        }
        .h5_names {
          display: inline-block;
          margin: 0;
          font-size: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          width: 320px;
          -webkit-box-orient: vertical;
          cursor: pointer;
        }
        .mark_box {
          display: flex;
          img {
            width: 14px;
            // height: 14px;
          }
          & > div {
            margin-left: 10px;
          }
        }
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_item_top_left_flex_tag {
          padding-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;

          margin-bottom: 1px;
          width: 500px;

          .hidden_label_box {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            flex-wrap: wrap;
          }
          span {
            box-sizing: border-box;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            padding: 1px 6px;
            margin-right: 10px;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .releaseMechanism {
          width: 450px;
          padding: 10px 0px;
          font-size: 16px;
        }
      }
    }
    .storeCenter_item_top_center {
      display: flex;
      width: 30%;
      .count_box {
        height: 80px;
        width: 120px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;
        // &:nth-of-type(1) {
        //   border-right: 1px solid #e8e8e8;
        // }
        & > div:nth-of-type(1) {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;

          color: #078bff;
        }
        & > div:nth-of-type(2) {
          margin-top: 20px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
      }
    }
    .storeCenter_item_top_right {
      align-items: stretch;
      height: 100%;
      position: relative;
      .operation_area {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 200px;

        .buttom_right {
          height: 36px;
          line-height: 1px;
        }
        .release {
          display: inline-block;
          width: 89px;
          height: 36px;
          background: #4e93fb;
          border: 1px solid #4e93fb;
          border-radius: 2px;
          color: #ffffff;
          cursor: pointer;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
        }
        .shelf {
          display: inline-block;
          width: 89px;
          height: 36px;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
          background: #ff7d18;
          /* chengs */

          border: 1px solid #ff7d18;
          border-radius: 2px;
          cursor: pointer;
          color: #ffffff;
        }
      }
      .details {
        text-align: right;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #999999;
        margin-top: 5px;
      }
    }
  }
}
.office {
  color: #4e93fb;

  cursor: pointer;
}

.support {
  cursor: pointer;
  color: #fd523f;
}

::v-deep .disinput .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 382px;
}
::v-deep .disinput .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 381px;
  border-collapse: separate;
  border-spacing: 0;
}
</style>
