import request from '@/utils/request'



//报告一览查询
export function listPage(query) {
  return request({
    url: '/reportGinseng/listPage',
    method: 'get',
    params:query
  })
}


//批量设置报告下架
export function updateOffShelf(data) {
  return request({
    url: '/reportGinseng/reportUnShelve',
    method: 'post',
    data
  })
}
//批量设置报告上架
export function updateOnShelf(data) {
  return request({
    url: '/reportGinseng/reportGrounding',
    method: 'post',
    data
  })
}
//报告冻结
export function reportFreeze(data) {
  return request({
    url: '/reportGinseng/reportFreeze',
    method: "post",
    data
  })
}
//报告解冻
export function reportThawing(data) {
  return request({
    url: '/reportGinseng/reportThawing',
    method: "post",
    data
  })
}
//根据主键编号查询顶部
export function selectById(query) {
    return request({
      url: '/reportGinseng/listTopById',
      method: 'get',
      params: query
    })
  }


//报告查看一览
export function listUserCheckPage(query) {
  return request({
    url: '/reportGinseng/listUserCheckPage',
    method: 'get',
    params: query
  })
}
//报告分享一览
export function listUserSharePage(query) {
  return request({
    url: '/reportGinseng/listUserSharePage',
    method: 'get',
    params: query
  })
}
//报告查看人数
export function userCheck(query) {
  return request({
    url: '/informationGinseng/userCheck',
    method: 'get',
    params: query
  })
}
//报告分享数
export function userShare(query) {
  return request({
    url: '/informationGinseng/userShare',
    method: 'get',
    params: query
  })
}
//报告下载人数
export function userDown(query) {
  return request({
    url: '/reportGinseng/userDown',
    method: 'get',
    params: query
  })
}
//报告下载一览查询
export function listUserDownPage(query) {
  return request({
    url: '/reportGinseng/listUserDownPage',
    method: 'get',
    params: query
  })
}


//批量设置报告类型---通用版
export function reportSave(data) {
  return request({
    url: '/reportGinseng/save',
    method: 'post',
    data
  })
}


//报告详情
export function reportById(query) {
  return request({
    url: '/reportGinseng/getReportById',
    method: 'GET',
    params: query
  })
}

//报告编辑
export function reportUpdate(data) {
  return request({
    url: '/reportGinseng/update',
    method: 'POST',
    data
  })
}

// //校验报告名称
// export function checkReportName(data) {
//   return request({
//     url: '/companyReport/checkReportName',
//     method: 'get',
//     params: data
//   })
// }


// // 查询数据 /companyReport/getSystemReportParam
// export function getSystemReportParam(data) {
//   return request({
//     url: '/companyReport/getSystemReportParam',
//     method: 'get',
//     params: data
//   })
// }


